var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-file-compare","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("Versões da BDGD")])]},proxy:true}])},[_c('v-text-field',{staticClass:"ml-auto mt-4 break-search",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{attrs:{"headers":_vm.headers,"sort-by":['data_registro', 'versao'],"sort-desc":['data_registro', 'versao'],"items":_vm.items,"search":_vm.search,"headerProps":{
            sortByText: 'Ordenar Por'
          },"footer-props":{
            itemsPerPageText: 'Itens por Página'
          },"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"mobile-breakpoint":800,"no-data-text":"Nenhuma versão de BDGD encontrada","no-results-text":"Nenhum registro correspondente encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("addLeadingZero")(item.id))+" ")]}},{key:"item.data_registro",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatToMonth")(item.data_registro))+" v"+_vm._s(item.versao)+" "+_vm._s(item.modulo10_antigo ? '(Módulo 10 Antigo)' : '')+" ")]}},{key:"item.titulo",fn:function(ref){
          var item = ref.item;
return [_c('v-responsive',{attrs:{"max-width":"170"}},[_c('v-text-field',{on:{"blur":function($event){return _vm.setTituloVersao(item.id, item.titulo)}},model:{value:(item.titulo),callback:function ($$v) {_vm.$set(item, "titulo", $$v)},expression:"item.titulo"}})],1)]}},{key:"item.registros",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.registros))+" ")]}},{key:"item.ordinaria",fn:function(ref){
          var item = ref.item;
return [_c('bdgd-ordinaria',{attrs:{"ordinaria":item.ordinaria}})]}},{key:"item.cor",fn:function(ref){
          var item = ref.item;
return [_c('v-responsive',{attrs:{"max-width":"200"}},[_c('input-color',{attrs:{"label":null,"selectedColor":item.cor},on:{"colorSelected":function (color) { return _vm.setCorBdgd(item.id, color); }}})],1)]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(_vm.usuarioAdmin && (item.registros > 0 || item.ordinaria))?_c('v-btn',{staticClass:"px-1",attrs:{"min-width":"0","fab":"","icon":"","x-small":"","color":item.ordinaria ? 'red' : 'green',"title":item.ordinaria
                  ? 'Definir versão da BDGD como não ordinária'
                  : 'Definir versão da BDGD como ordinária'},on:{"click":function () { return _vm.inverteBdgdOrdinaria(item.id); }}},[(item.ordinaria)?_c('v-icon',[_vm._v("mdi-thumb-down-outline")]):_c('v-icon',[_vm._v("mdi-thumb-up-outline")])],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"18"},on:{"click":function($event){return _vm.$router.push(
                      ("/geracao-bdgd/validacoes-bdgd/status-geral-bdgd/" + (item.id))
                    )}}},'v-icon',attrs,false),on),[_vm._v(" mdi-database-eye-outline ")])]}}],null,true)},[_c('span',[_vm._v("Visualizar o status geral de validações da BDGD")])])]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":_vm.getVersoesBdgds}},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}])},[_c('span',[_vm._v("Clique aqui para recarregar as versões da BDGD")])])]},proxy:true}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }